<template>
  <div id="app">
    <!-- <Navbar /> -->
    <Wip />
    <!-- <router-view /> -->
  </div>
</template>

<script>
// import Navbar from "@/components/Navbar.vue";
import Wip from "@/views/Wip.vue";
export default {
  components: {
    // Navbar,
    Wip,
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/zhj6lpd.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

body {
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}

button {
  outline: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #111111;
  background-color: #ffffff;
  // height: 200vh;
  font-family: brandon-grotesque, sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
p.title {
  font-size: 3rem;
  font-family: brandon-grotesque, sans-serif;
  font-weight: bold;
  color: #111111;
  text-align: center;
  text-transform: uppercase;
}
p.subtitle {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #111111;
  text-align: center;
  text-transform: uppercase;
}
.bordeaux {
  color: #700f16;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
}
span.bolder {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: brandon-grotesque, sans-serif;
  text-transform: uppercase;
}
// button.btn {
//   border: none;
//   box-shadow: none;
//   border-radius: 20px;
//   padding: 20px;
//   width: 100%;
//   text-transform: uppercase;
//   font-size: 1rem;
//   font-weight: 600;
//   color: white;
//   background: linear-gradient(
//     76deg,
//     rgba(4, 34, 99, 1) 0%,
//     rgb(2, 14, 80) 100%
//   );
//  font-family: brandon-grotesque, sans-serif;
// }

button.btn {
  border: none;
  box-shadow: none;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 900;
  color: black;
  font-family: brandon-grotesque, sans-serif;
  background-color: rgb(243, 243, 243);
  transition: all 200ms;
  &.tapped {
    background-color: rgb(238, 238, 238);
  }
}
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
