<template>
  <div class="wip-container flex-col">
    <div class="maintenance-content flex-col">
      <div class="icon">
        <img src="../assets/maintenance.svg" alt="Maintenance" />
      </div>
      <p class="title">Site en maintenance</p>
      <p class="subtitle">Nous améliorons votre expérience</p>
      <div class="message">
        <p>Nous effectuons actuellement des travaux de maintenance sur notre site web.</p>
        <p>Nous serons de retour très prochainement avec une nouvelle expérience.</p>
        <p>Merci de votre patience et de votre compréhension.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wip"
};
</script>

<style lang="scss" scoped>
.wip-container {
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #ffffff;
  
  .maintenance-content {
    max-width: 800px;
    padding: 3rem 2rem;
    text-align: center;
    
    .icon {
      margin-bottom: 2rem;
      img {
        width: 120px;
        height: auto;
      }
    }
    
    .title {
      margin-bottom: 1rem;
    }
    
    .subtitle {
      margin-bottom: 3rem;
    }
    
    .message {
      margin-bottom: 3rem;
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 1.1rem;
        line-height: 1.6;
        margin: 0.5rem 0;
      }
    }
    
    .contact-info {
      background-color: rgb(243, 243, 243);
      padding: 1.5rem;
      border-radius: 10px;
      margin-top: 1rem;
      
      p {
        font-family: "Open Sans", sans-serif;
        margin: 0.5rem 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .wip-container {
    padding: 1rem;
    
    .maintenance-content {
      padding: 2rem 1rem;
      
      .title {
        font-size: 2.5rem;
      }
      
      .message p {
        font-size: 1rem;
      }
    }
  }
}
</style>
